<script setup lang="ts">
import { useCurrencyInput } from "vue-currency-input";

interface Props {
  modelValue: any;
  currency?: string;
  noDecimal?: boolean;
}

const props = defineProps<Props>();
defineEmits(["update:modelValue", "change"]);

defineOptions({
  inheritAttrs: true,
});

// The following code is a workaround to make the money input work
// with a dynamic currency prop.

const useBaseCurrencyInput = (currency: string) => {
  return useCurrencyInput({
    currency,
    hideCurrencySymbolOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    hideGroupingSeparatorOnFocus: false,
    locale: "pt-BR",
    autoDecimalDigits: !props.noDecimal,
    valueRange: { min: 0, max: 10000000 },
  });
};

const {
  inputRef: brlInputRef,
  numberValue: brlNumberValue,
  setValue: brlSetValue,
} = useBaseCurrencyInput("BRL");

const {
  inputRef: usdInputRef,
  numberValue: usdNumberValue,
  setValue: usdSetValue,
} = useBaseCurrencyInput("USD");

const {
  inputRef: hcuInputRef,
  numberValue: hcuNumberValue,
  setValue: hcuSetValue,
} = useBaseCurrencyInput("HCU");

watch(
  () => props.modelValue,
  (value) => {
    if (value !== brlNumberValue.value) {
      brlSetValue(value);
    }

    if (value !== usdNumberValue.value) {
      usdSetValue(value);
    }

    if (value !== hcuNumberValue.value) {
      hcuSetValue(value);
    }
  }
);
</script>

<template>
  <input v-if="currency === 'USD'" ref="usdInputRef" type="text" />
  <input v-else-if="currency === 'HCU'" ref="hcuInputRef" type="text" />
  <input v-else ref="brlInputRef" type="text" />
</template>
